import React, { FC } from "react";
import styled from "@emotion/styled";
import { css, Global } from "@emotion/react";

import section1 from "../../../images/recruitment/creator/section1.png";
import section2 from "../../../images/recruitment/creator/section2.png";
import section3 from "../../../images/recruitment/creator/section3.png";
import buttonText from "../../../images/recruitment/creator/button/text.png";

const DOCS_URL = 'https://docs.google.com/forms/d/e/1FAIpQLSfgDutdd4k3qZKT8IEK0yxbAVt-03rzcEdmb7ajx8sdu-4tJQ/viewform'

const IPHONE_MAX = 428;
const BUTTON_COLOR = '#00DD99';
const BACKGROUND_COLOR = '#FFFDF4';

const Creator: FC = () => {
  const handleClick = () => { window.location.href = DOCS_URL }
  return (
    <>
      <GlobalStyle />
      <Container>
        <RecruitmentPhoto src={section1}/>
        <RecruitmentPhoto src={section2}/>
        <RecruitmentPhoto src={section3}/>
        <GoToDocsSection>
          <GoToDocs onClick={handleClick} title="Go to the form to register" aria-label="Go to the form to register"/>
        </GoToDocsSection>
      </Container>
    </>
  );
};

const GlobalStyle = () => <Global styles={css`
  *{ box-sizing:border-box}
  body { margin: 0;}
`} />

const Container = styled.article`
  display:flex;
  flex-direction: column;
  justify-content: center;
  align-items: center; 
  position:relative;
`
const RecruitmentPhoto = styled.img`
  max-width: ${IPHONE_MAX}px;
  width: 100%;
  pointer-events: none;
`
const GoToDocsSection = styled.section`
  max-width: ${IPHONE_MAX}px;
  width: 100%;
  height: 104px;
  padding:24px 32px;
  background: linear-gradient(180deg, rgba(255, 253, 244, 0) 0%, ${BACKGROUND_COLOR} 100%);

  display: flex;
  justify-content: center;
  align-items: center;

  position: sticky;
  bottom: 0;
`

const GoToDocs = styled.button`
  width: 100%;
  height: 56px;
  border-radius: 80px;
  background: ${BUTTON_COLOR} url(${buttonText}) no-repeat center;
  user-select: none;
  border:none;
`

export default Creator;